<template>
  <b-overlay :show="loading" rounded="sm">
    <div v-if="client">
      <b-card>
        <b-row>
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-between">
              <div>
                <div>
                  <h4 class="mb-0">
                    {{ client.full_name }}
                  </h4>
                  <span class="card-text">{{ client.email }}</span> <br />
                  <span class="card-text">
                    Registrado desde:
                    {{
                      dateFormat(client.created_at, "DD MMMM YYYY, h:m:ss a")
                    }}
                  </span>
                </div>
              </div>
              <div>
                <div class="d-flex align-items-center mr-2">
                  <b-avatar variant="light-danger" rounded>
                    <feather-icon icon="DollarSignIcon" size="25" />
                  </b-avatar>
                  <div class="ml-1">
                    <h1 class="mb-0">
                      {{ formatCurrency(client.due + client.due_addons) }}
                    </h1>
                    <h3>Deuda total</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                class="btn-icon mr-1"
                @click="() => $router.go(-1)"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
              <b-button
                variant="success"
                class="btn-icon mr-1"
                @click="refresh"
              >
                <feather-icon icon="RefreshCwIcon" size="16" />
              </b-button>
              <!--
              <b-button
                variant="success"
              >
                Editar
              </b-button>-->
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deleteClient"
              >
                Eliminar
              </b-button>
            </div>

            <!-- User Stats -->
            <div class="d-flex align-items-center mt-2">
              <div class="d-flex align-items-center mr-2">
                <b-avatar variant="light-danger" rounded>
                  <feather-icon icon="DollarSignIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ formatCurrency(client.due) }}
                  </h5>
                  <small>Mensajeria</small>
                </div>
              </div>

              <div class="d-flex align-items-center mr-2">
                <b-avatar variant="light-danger" rounded>
                  <feather-icon icon="DollarSignIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ formatCurrency(client.due_addons) }}
                  </h5>
                  <small>Compras</small>
                </div>
              </div>

              <div class="d-flex align-items-center mr-2">
                <b-avatar variant="light-success" rounded>
                  <feather-icon icon="DollarSignIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ totalOrders }}
                  </h5>
                  <small>Total en ordenes</small>
                </div>
              </div>

              <div class="d-flex align-items-center mr-2">
                <b-avatar variant="light-success" rounded>
                  <feather-icon icon="BoxIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ orders.length }}
                  </h5>
                  <small>Ordenes hechas</small>
                </div>
              </div>

              <div class="d-flex align-items-center mr-2">
                <b-avatar variant="light-success" rounded>
                  <feather-icon icon="FileTextIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ invoices.length }}
                  </h5>
                  <small>Facturas electronicas</small>
                </div>
              </div>
            </div>
          </b-col>

          <!-- Right Col: Table -->
          <b-col cols="12" xl="6">
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CreditCardIcon" class="mr-75" />
                  <span class="font-weight-bold">Tipo identificación</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ identificationInLetter(client.identification_type) }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CreditCardIcon" class="mr-75" />
                  <span class="font-weight-bold">Número de identificación</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ client.identification_number }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="PhoneIcon" class="mr-75" />
                  <span class="font-weight-bold">Teléfono</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ client.phone }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CheckIcon" class="mr-75" />
                  <span class="font-weight-bold">Status</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ client.active ? "Activo" : "Inactivo" }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="FlagIcon" class="mr-75" />
                  <span class="font-weight-bold">Provincia</span>
                </th>
                <td class="pb-50">
                  {{ client.province ? client.province.name : "S/N" }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="FlagIcon" class="mr-75" />
                  <span class="font-weight-bold">Canton</span>
                </th>
                <td class="pb-50">
                  {{ client.canton ? client.canton.name : "S/N" }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="FlagIcon" class="mr-75" />
                  <span class="font-weight-bold">Distrito</span>
                </th>
                <td class="pb-50">
                  {{ client.district ? client.district.name : "S/N" }}
                </td>
              </tr>
              <tr>
                <th>
                  <feather-icon icon="FlagIcon" class="mr-75" />
                  <span class="font-weight-bold">Barrio</span>
                </th>
                <td>
                  {{ client.barrio ? client.barrio.name : "S/N" }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col>
          <b-card title="Ordenes">
            <b-overlay :show="loading_orders" rounded="sm">
              <b-button
                v-if="statusFilter.id == 'no_paid' && orders.length"
                style="position:absolute;right: 0;top:-30px"
                variant="success"
                class="btn-icon mr-1"
                @click="payAllOrders()"
              >
                <feather-icon icon="DollarSignIcon" />

                Pagar todo en el rango de fechas seleccionado
              </b-button>

              <b-row class="mb-2">
                <b-col cols="12" md="6" class="mb-md-0 d-flex">
                  <div class="mr-1">
                    <label>Por pagina</label> <br />
                    <v-select
                      v-model="per_page_orders"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPagesOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block"
                      @input="getOrders"
                    />
                  </div>
                  <div>
                    <label for="">Entre fechas</label> <br />
                    <date-picker
                      range-separator="-"
                      v-model="rangePicker"
                      :default-value="rangePicker"
                      range
                      append-to-body
                      lang="es"
                      format="YYYY-MM-DD"
                      style="width: 200px"
                      class="mr-1"
                      :clearable="false"
                    ></date-picker>
                  </div>
                  <div>
                    <label for="">Por estado</label>
                    <v-select
                      v-model="statusFilter"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="copy_statuses"
                      :clearable="false"
                      label="name"
                      style="width:170px"
                    />
                  </div>
                </b-col>
              </b-row>

              <b-table
                ref="refInvoiceListTable"
                :items="orders"
                responsive
                :fields="tableOrdersColumns"
                primary-key="id"
                show-empty
                empty-text="El cliente no tiene ordenes registradas"
                class="position-relative"
              >
                <template #cell(code)="data">
                  <router-link
                    v-b-tooltip.hover.top="`${data.item.description}`"
                    :to="{ name: 'order-detail', params: { id: data.item.id } }"
                  >
                    # {{ data.item.code }}
                  </router-link>
                </template>

                <template #cell(created_at)="data">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ dateFormat(data.item.created_at, "DD-MM-YYYY") }}
                  </span>
                  <small class="text-muted">
                    {{ dateFormat(data.item.created_at, "hh:mm A") }}
                  </small>
                </template>

                <template #cell(total)="data">
                  {{ formatCurrency(data.item.total) }}
                </template>

                <template #cell(additional_total)="data">
                  {{ formatCurrency(data.item.additional_total) }}
                </template>

                <template #cell(order_latest_status)="data">
                  <b-overlay
                    :show="loading_btn_change_status == data.item.id"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                  >
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :text="data.item.order_latest_status.name"
                      :variant="
                        getColorStatus(data.item.order_latest_status.code)
                      "
                      size="sm"
                      :disabled="
                        disabledActionsToOrder(
                          data.item.order_latest_status.code
                        ) ||
                          (!(user_state && user_state.role == 'admin') &&
                            !permissions.includes('change_status_order'))
                      "
                    >
                      <b-dropdown-item
                        v-for="(status, i) in statuses"
                        :key="i"
                        @click="changeStatusOrder(data.item.id, status.id)"
                        :disabled="data.item.last_status_id == status.id"
                      >
                        {{ status.name }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-overlay>
                </template>

                <template #cell(distributor)="data">
                  <div class="d-flex">
                    <b v-if="data.item.distributor">
                      {{ data.item.distributor.user.name }}
                      {{ data.item.distributor.user.surname }}
                    </b>
                    <b v-else>
                      S/N
                    </b>
                  </div>
                </template>

                <template #cell(due)="data">
                  <div class="d-flex align-items-center">
                    <span class="font-weight-bolder mr-1">
                      {{ formatCurrency(data.item.due.due_total) }}
                    </span>
                    <feather-icon icon="TrendingDownIcon" class="text-danger" />
                  </div>
                </template>

                <template #cell(total_paid)="data">
                  <div class="d-flex align-items-center">
                    <span class="font-weight-bolder mr-1">
                      {{ formatCurrency(data.item.total_paid) }}
                    </span>
                    <feather-icon icon="TrendingUpIcon" class="text-success" />
                  </div>
                </template>

                <template #cell(actions)="data">
                  <b-dropdown variant="link" toggle-class="p-0" no-caret dropup>
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="20"
                        class="text-body"
                      />
                    </template>
                    <template
                      v-if="
                        (!['cancelled', 'scheduled'].includes(
                          data.item.order_latest_status.code
                        ) &&
                          !data.item.paid &&
                          user_state &&
                          user_state.role == 'admin') ||
                          permissions.includes('pay_order')
                      "
                    >
                      <b-dropdown-item @click="openPaymentForm(data.item)">
                        <feather-icon icon="DollarSignIcon" />
                        <span class="align-middle ml-50">Pagar</span>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </template>
              </b-table>

              <div class="text-center">
                <b-pagination
                  v-model="current_page_orders"
                  :per-page="per_page_orders"
                  hide-goto-end-buttons
                  :total-rows="totalRegistersOrders"
                />
              </div>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card title="Facturas electronicas">
            <b-overlay :show="loading_invoices" rounded="sm">
              <b-row class="mb-2">
                <b-col cols="12" md="6" class="mb-md-0">
                  <label>Por pagina</label> <br />
                  <v-select
                    v-model="per_page_invoices"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPagesOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block"
                    @input="getInvoices"
                  />
                </b-col>
              </b-row>

              <b-table
                ref="refInvoiceListTable"
                :items="invoices"
                responsive
                :fields="tableInvoicesColumns"
                primary-key="id"
                show-empty
                empty-text="El cliente no tiene facturas registradas"
                class="position-relative"
              >
                <template #cell(reference)="data">
                  <router-link :to="`/invoice-detail/${data.item.id}`">
                    {{ data.item.reference }}
                  </router-link>
                </template>

                <template #cell(created_at)="data">
                  {{ dateFormat(data.item.created_at, "DD MMMM YYYY") }}
                </template>

                <template #cell(hacienda)="data">
                  <status-hacienda :status_hacienda="data.item.hacienda" />
                </template>
              </b-table>

              <div class="text-center">
                <b-pagination
                  v-model="current_page_invoices"
                  :per-page="per_page_invoices"
                  hide-goto-end-buttons
                  :total-rows="totalRegistersInvoices"
                />
              </div>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>

      <b-sidebar
        :visible="showPaymentForm"
        @hidden="showPaymentForm = false"
        shadow
        bg-variant="white"
        title="Pagar"
        right
        width="380px"
      >
        <b-card-text>
          <div class="p-2">
            <form-payment-order
              :rangeDates="rangePicker"
              :clientId="client.id"
              :order="orderPayment"
              @payOrder="eventToOrder"
            />
          </div>
        </b-card-text>
      </b-sidebar>
    </div>
  </b-overlay>
</template>

<script>
import ClientService from "@/services/client.service";
import Ripple from "vue-ripple-directive";
import moment from "moment";
moment.locale("es");
import Swal from "sweetalert2";
import {
  BOverlay,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BCardText,
  VBTooltip
} from "bootstrap-vue";
import {
  identificationInLetter,
  currencyFormat,
  perPageBaseOptions,
  confirmAlert
} from "@/helpers";
import vSelect from "vue-select";
import utilitiesService from "@/services/utilities.service";
import orderService from "@/services/order.service";

import { mapState } from "vuex";
import FormPaymentOrder from "@/components/FormPaymentOrder";

import DatePicker from "vue2-datepicker";

export default {
  components: {
    DatePicker,
    BBadge,
    vSelect,
    BOverlay,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    FormPaymentOrder,
    BCardText
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  computed: {
    ...mapState({
      user_state: state => state.app.user,
      permissions: state => state.app.permissions
    })
  },
  data() {
    return {
      copy_statuses: [],
      orderPayment: "",
      showPaymentForm: false,
      loading_btn_change_status: false,
      client: null,
      orders: [],
      invoices: [],
      loading: false,
      loading_orders: false,
      loading_invoices: false,
      perPagesOptions: perPageBaseOptions,
      per_page_orders: 10,
      per_page_invoices: 10,
      current_page_orders: 1,
      current_page_invoices: 1,
      totalRegistersOrders: 0,
      totalRegistersInvoices: 0,

      tableOrdersColumns: [
        { key: "code", label: "Código", sortable: true },
        { key: "created_at", label: "Fecha", sortable: true },
        { key: "distributor", label: "Repartidor", sortable: true },
        { key: "total", label: "Total", sortable: true },
        { key: "additional_total", label: "Extras", sortable: true },
        { key: "due", label: "Deuda", sortable: true },
        { key: "total_paid", label: "Pagado", sortable: true },
        { key: "order_latest_status", label: "Estatus", sortable: true },
        { key: "actions", label: "Acciones", sortable: false }
      ],
      tableInvoicesColumns: [
        { key: "reference", label: "Consecutivo" },
        { key: "created_at", label: "Fecha", sortable: true },
        { key: "hacienda", label: "Hacienda", sortable: true }
      ],
      totalOrders: 0,
      statuses: [],

      rangePicker: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ],

      statusFilter: { name: "No pagadas", id: "no_paid" }
    };
  },
  watch: {
    rangePicker() {
      if (this.rangePicker.length > 1) {
        this.getOrders();
      }
    },
    current_page_orders() {
      this.getOrders();
    },
    current_page_invoices() {
      this.getInvoices();
    },
    per_page_orders() {
      this.getOrders();
    },
    per_page_invoices() {
      this.getInvoices();
    },
    statusFilter() {
      this.getOrders();
    }
  },
  methods: {
    payAllOrders() {
      this.orderPayment = "all";
      this.showPaymentForm = true;
    },
    openPaymentForm(order) {
      this.orderPayment = order;
      this.showPaymentForm = true;
    },
    eventToOrder(data) {
      if (data.all) {
        this.showPaymentForm = false;
        this.refresh();
        this.orderPayment = "";
        return;
      }

      this.showPaymentForm = false;
      const index = this.orders.findIndex(i => i.id == data.id);
      this.orders[index].due = data.due;
      this.orders[index].total_paid = data.total_paid;
      this.orders[index].additional_total = data.additional_total;
      this.orders = JSON.parse(JSON.stringify(this.orders));
    },
    async changeStatusOrder(orderId, statusId) {
      try {
        let text_motive = "";
        const canMotive = await Swal.fire({
          title: "Ingrese el motivo del cambio de estado de la orden",
          input: "text",
          inputAttributes: { autocapitalize: "off" },
          customClass: { confirmButton: "mr-1" },
          showCancelButton: true,
          confirmButtonText: "Continuar",
          cancelButtonText: "Cancelar",
          showLoaderOnConfirm: true,
          preConfirm: async motive => {
            if (motive) {
              text_motive = motive;
            } else {
              Swal.fire.showValidationMessage(`Ingresa un motivo`);
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        });

        if (canMotive.isConfirmed) {
          this.loading_btn_change_status = orderId;

          const { data: res } = await orderService.changeStatusOrder({
            order_id: orderId,
            status_id: statusId,
            motive: text_motive
          });

          if (res.success) {
            const indexOrder = this.orders.findIndex(i => i.id == orderId);
            this.orders[indexOrder].last_status_id =
              res.data.order.last_status_id;
            this.orders[indexOrder].order_latest_status =
              res.data.order.order_latest_status;
            this.orders = JSON.parse(JSON.stringify(this.orders));

            if (res.data.electronic_invoice) {
              const confirm = await confirmAlert(
                "Esta orden fue marcada para generar factura electronica",
                "¿Desea generar la factura electronica inmediatamente?"
              );
              if (confirm.isConfirmed) {
                router.push({
                  path: "/create-invoice",
                  query: { order: res.data.order.id }
                });
              }
            }
          }

          this.loading_btn_change_status = false;
        }
      } catch (error) {
        console.log(error);
        this.loading_btn_change_status = false;
        Swal.fire({
          title: "Ocurrio un problema",
          text: "",
          icon: "warning"
        });
      }
    },
    disabledActionsToOrder(code_status) {
      const status_for_disabled_action = ["finished", "cancelled"];
      if (status_for_disabled_action.includes(code_status)) {
        return true;
      } else {
        return false;
      }
    },
    getColorStatus(code_status) {
      if (code_status == "in_process") return "info";
      if (code_status == "in_route") return "warning";
      if (code_status == "in_destiny") return "warning";
      if (code_status == "delivered") return "success";
      if (code_status == "finished") return "primary";
      if (code_status == "cancelled") return "danger";
    },
    async getClient() {
      try {
        this.loading = true;
        const { data: res } = await ClientService.getClient({
          id: this.$route.params.id,
          query: "client"
        });
        if (res.success) {
          this.client = res.data.client;
        } else {
          this.swal(
            "Ha ocurrido un error al cargar la información del cliente"
          );
        }
        this.loading = false;
      } catch (error) {
        this.swal("Ha ocurrido un error al cargar la información del cliente");
      }
    },
    totalAmountOrders() {
      let total = 0;
      this.orders.forEach(i => {
        if (!["cancelled", "scheduled"].includes(i.order_latest_status.code)) {
          total += parseFloat(i.total);
        }
      });
      this.totalOrders = this.formatCurrency(total);
    },
    async getOrders() {
      try {
        this.loading_orders = true;
        const { data: res } = await ClientService.getClient({
          id: this.$route.params.id,
          per_page: this.per_page_orders,
          page: this.current_page_orders,
          query: "orders",
          daterange: this.rangePicker,
          status: this.statusFilter
        });
        if (res.success) {
          this.orders = res.data.orders.data;
          this.totalRegistersOrders = res.data.orders.total;
          this.totalAmountOrders();
        } else {
          this.swal("Ha ocurrido un error al cargar las ordenes");
        }
        this.loading_orders = false;
      } catch (error) {
        this.swal("Ha ocurrido un error al cargar las ordenes");
      }
    },
    async getInvoices() {
      try {
        this.loading_invoices = true;
        const { data: res } = await ClientService.getClient({
          id: this.$route.params.id,
          per_page: this.per_page_invoices,
          page: this.current_page_invoices,
          query: "invoices"
        });
        if (res.success) {
          this.invoices = res.data.invoices.data;
          this.totalRegistersInvoices = res.data.invoices.total;
        } else {
          this.swal("Ha ocurrido un error al cargar las facturas");
        }
        this.loading_invoices = false;
      } catch (error) {
        this.swal("Ha ocurrido un error al cargar las facturas");
      }
    },
    async deleteClient() {
      try {
        const confirm = await confirmAlert(
          "Eliminar Cliente",
          "Vas a eliminar el cliente deseas continuar"
        );
        if (!confirm.isConfirmed) return;

        const { data: res } = await ClientService.deleteClient({
          id: this.client.id
        });

        store.dispatch("app/getGeneralData");

        this.$router.push("/clients");
      } catch (error) {
        console.log(error);
      }
    },
    async getStatusesOrder() {
      const { data: res } = await utilitiesService.getStatusesOrder();
      this.statuses = res;

      this.copy_statuses = [...res];
      console.log(res);
      this.copy_statuses.unshift({
        name: "Todas",
        id: ""
      });
      this.copy_statuses.push({
        name: "Pagadas",
        id: "paid"
      });
      this.copy_statuses.push({
        name: "No pagadas",
        id: "no_paid"
      });
    },
    formatCurrency(amount) {
      return currencyFormat(amount);
    },
    identificationInLetter(type) {
      return identificationInLetter(type);
    },
    dateFormat(date, format = "DD/MM/YYYY") {
      return moment(date).format(format);
    },
    swal(title, content = "") {
      Swal.fire({
        title: title,
        text: content,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: false
      });
    },
    refresh() {
      this.getClient();
      this.getOrders();
      this.getInvoices();
    }
  },
  created() {
    this.getClient();
    this.getOrders();
    this.getInvoices();
    this.getStatusesOrder();
  }
};
</script>
